import axios from "axios";

axios.interceptors.response.use(null, (error) => {
  if (error.response.status === 409) {
    return (window.location = "/logout");
  }

  return Promise.reject(error);
});

function setCommonHeader(header, value) {
  axios.defaults.headers.common[header] = value;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setCommonHeader,
};
