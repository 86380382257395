import React from "react";

const NotFound = () => {
  return (
    <div className="not-found container">
      <center>
        <h1>Page Not Found</h1>
      </center>
    </div>
  );
};

export default NotFound;
