import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import * as _ from "lodash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  getEventDetails,
  getEventRSVPTypes,
  getEventMenuItems,
  upsertRSVP,
  upsertMenuOrder,
} from "../services/controller";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Event = (props) => {
  const { handleSetAlert, user } = props;
  const { eventID } = useParams();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState();
  const [rsvpTypes, setRSVPTypes] = useState();
  const [menuItems, setMenuItems] = useState();
  const [selectedRSVPType, setSelectedRSVPType] = useState({ code: "X" });
  const [guests, setGuests] = useState();
  const [showRSVPDetails, setShowRSVPDetails] = useState(false);
  const [menuOrder, setMenuOrder] = useState([]);

  const fetchEventDetails = async () => {
    try {
      setLoading(true);
      const eventDetails = await getEventDetails(eventID);
      setEvent(eventDetails);
      const eventRSVPTypes = await getEventRSVPTypes(eventID);
      setRSVPTypes(eventRSVPTypes);
      const eventMenuItems = await getEventMenuItems(eventID);
      setMenuItems(eventMenuItems);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleSetAlert("error", "Error fetching event details");
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchEventDetails();
    }
  }, [user]);

  const handleRSVPClick = (e) => {
    const rsvpTypeID = e.target.value;
    const rsvpType = _.find(rsvpTypes, { id: parseInt(rsvpTypeID) });
    setSelectedRSVPType(rsvpType);
    if (rsvpType && rsvpType.code === "A") {
      setShowRSVPDetails(true);
    } else {
      setShowRSVPDetails(false);
    }
  };

  const handleSetMenuOrderQty = (e) => {
    const idArray = e.currentTarget.id.split("-");
    const menuItemID = parseInt(idArray[idArray.length - 1]);
    const menuItemQuantity = e.target.value;
    const newMenuOrder = [...menuOrder];
    if (_.find(newMenuOrder, { id: menuItemID })) {
      _.remove(newMenuOrder, { id: menuItemID });
    }
    if (menuItemQuantity !== "") {
      newMenuOrder.push({ id: menuItemID, quantity: menuItemQuantity });
    }
    setMenuOrder([...newMenuOrder]);
  };

  const handleRSVP = async () => {
    try {
      await upsertRSVP(eventID, selectedRSVPType, guests);
      await upsertMenuOrder(eventID, menuOrder);
      const updatedEvent = { ...event, userHasRSVP: true };
      setEvent(updatedEvent);
      handleSetAlert("success", "Thank you!");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="event-page-container">
      <center>
        {loading && (
          <Box sx={{ width: "25%" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && event && (
          <Box>
            <div className="event-content-container">
              {event.userHasRSVP && (
                <Alert sx={{ width: "80%" }} severity="success">
                  You have RSVP'd to this event!
                </Alert>
              )}
              {/* <div className="user container">
                <ProfilePicture user={event.user} size={40} />
                <p>{event.user.username}</p>
              </div> */}
              <h1>{event.name}</h1>
              <h3>Date & Time</h3>
              <p className="event-date">
                {dateFormat(event.scheduledTime, "mmmm dS, yyyy h:MM TT")}
              </p>
              <h3>Location</h3>
              <p className="event-location">
                {event.location.name} <br />
                {event.location.address.line1} <br />
                {event.location.address.city}, {event.location.address.state}{" "}
                {event.location.address.zip}
              </p>
              <h3>Cost</h3>
              <p>Cash Only</p>
              <p className="event-cost">{event.cost}</p>
              <br />
              <center>
                <Box className="content">
                  <div
                    style={{ textAlign: "left", margin: 25 }}
                    dangerouslySetInnerHTML={{ __html: event.content }}
                  ></div>
                </Box>
              </center>
            </div>
            {!event.userHasRSVP && (
              <div className="rsvp container">
                <h1>RSVP</h1>
                <FormControl>
                  <RadioGroup
                    name="rsvp-buttons-group"
                    onChange={handleRSVPClick}
                  >
                    {rsvpTypes.map((r) => (
                      <FormControlLabel
                        key={r.id}
                        value={r.id}
                        control={<Radio />}
                        label={r.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {showRSVPDetails && (
                  <div>
                    <br />
                    <br />
                    <TextField
                      id="guest-input"
                      label="Guests"
                      variant="outlined"
                      onChange={(e) => setGuests(e.target.value)}
                      sx={{ width: "50%" }}
                    />
                    <br />
                    <br />
                    <h3>Meal Order</h3>
                    <TableContainer component={Paper} sx={{ width: "90%" }}>
                      <Table sx={{ width: "90%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Item</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {menuItems.map((m) => (
                            <TableRow
                              key={m.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                <h4>{m.name}</h4>
                                <br />
                                {m.description}
                              </TableCell>
                              <TableCell align="center">
                                <TextField
                                  id={`txt-order-qty-${m.id}`}
                                  variant="outlined"
                                  onChange={handleSetMenuOrderQty}
                                  sx={{ width: "50%" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                <br />
                <br />
                <Button
                  onClick={() => {
                    handleRSVP();
                  }}
                >
                  RSVP
                </Button>
              </div>
            )}
            <br />
            <br />
            <br />
          </Box>
        )}
      </center>
    </div>
  );
};

export default Event;
