import React from "react";
import dateFormat from "dateformat";
import * as _ from "lodash";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EditIcon from "@mui/icons-material/Edit";
import ProfilePicture from "./profile-picture";
import { Link } from "react-router-dom";
import { adminCheck } from "../../services/auth";

const PostSummary = ({ post, handleLikeClick }) => {
  return (
    <div className="post summary container">
      <Box className="post-container" sx={{ border: 1, borderRadius: "16px" }}>
        <Link to={`/post/${post.id}`}>
          <h2 className="post-title">{post.title}</h2>
        </Link>
        <div className="user container">
          <ProfilePicture user={post.user} size={40} />
          <p>{post.user.username}</p>
        </div>
        <p className="post-date">
          {dateFormat(post.sysInserted, "mmmm dS, yyyy h:MM TT")}
        </p>
        <div className="tags container">
          {post.tags.map((t) => {
            return <Chip key={t.id} label={t.value} variant="outlined" />;
          })}
        </div>
        <div className="likes container">
          <Button
            className={post.likes.userLiked ? "liked" : "unliked"}
            id={`btn-post-like-${post.id}`}
            variant="outlined"
            startIcon={
              post.likes.userLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />
            }
            onClick={handleLikeClick}
          >
            {post.likes.count}
          </Button>
        </div>
        {adminCheck() && (
          <>
            <Button
              component={Link}
              to={`/edit-post/${post.id}`}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};

export default PostSummary;
