import React from "react";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

const ProfilePicture = (props) => {
  const { user, size } = props;
  const ppSize = size || 50;
  if (user.imageLink) {
    return (
      <Avatar
        className="prof-pic"
        alt="Profile Picture"
        src={user.imageLink}
        sx={{ width: ppSize, height: ppSize }}
      />
    );
  } else if (user.username) {
    return (
      <Avatar
        className="prof-pic"
        alt="Profile Picture"
        sx={{ width: ppSize, height: ppSize }}
      >
        {user.username.charAt(0)}
      </Avatar>
    );
  } else {
    return (
      <></>
      // <Avatar sx={{ width: ppSize, height: ppSize }}>
      //   <PersonIcon fontSize="large" />
      // </Avatar>
    );
  }
};

export default ProfilePicture;
