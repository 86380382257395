import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authCheck } from "../services/auth";
import config from "../config";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { updPassword } from "../services/controller";

const ResetPassword = (props) => {
  const { handleSetUser, handleSetAlert } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");

  if (authCheck()) {
    return (window.location = "/");
  }

  const handleResetPasssword = async () => {
    try {
      const res = await updPassword(token, password);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      return (window.location = "/");
    } catch (err) {
      handleSetAlert("error", err.response.data.message);
    }
  };

  return (
    <div className="login container">
      <h1>Register</h1>
      <center>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
        >
          <Stack spacing={2} direction="column">
            <TextField
              id="token-input"
              label="Invitation Token"
              variant="outlined"
              value={token}
              disabled
            />
            <TextField
              id="password-input"
              label="Password"
              type="password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="login" onClick={handleResetPasssword}>
              Reset Password
            </Button>
          </Stack>
        </Box>
      </center>
    </div>
  );
};

export default ResetPassword;
