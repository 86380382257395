import config from "../config";
import http from "./http";
import * as qs from "querystring";

export const updProfilePicture = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const httpConfig = {
        headers: {
          "x-upload-path": "users",
        },
      };
      const data = new FormData();
      data.append("upload", file);
      const updImageUrl = `${config.apiUrl}/users/image`;
      const res = await http.put(updImageUrl, data, httpConfig);
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPosts = (tag, sortBy, pageSize, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = {};
      if (tag) {
        qsData.tag = tag;
      }
      if (sortBy) {
        qsData.sortBy = sortBy;
      }
      if (pageSize) {
        qsData.pageSize = pageSize;
      }
      if (page) {
        qsData.page = page;
      }
      const qString = qs.encode(qsData);
      const url = `${config.apiUrl}/posts?${qString}`;
      const { data, headers } = await http.get(url);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const insLike = (postID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { postID };
      const url = `${config.apiUrl}/posts/likes`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const delLike = (postID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/posts/likes/${postID}`;
      await http.delete(url);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPostDetails = (postID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/posts/${postID}`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPostDetailsForEdit = (postID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/posts/${postID}/edit`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insComment = (postID, content) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { postID, content };
      const url = `${config.apiUrl}/posts/comments`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const delComment = (commentID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/posts/comments/${commentID}`;
      await http.delete(url);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const insPost = (title, content, editorState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { title, content, editorState };
      const url = `${config.apiUrl}/posts`;
      const { data } = await http.post(url, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insTag = (tag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { tag };
      const url = `${config.apiUrl}/tags`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const insPostTag = (postID, tagID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { postID, tagID };
      const url = `${config.apiUrl}/posts/tags`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const delPostTag = (postID, tagID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/posts/tags/${postID}/${tagID}`;
      await http.delete(url);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const getTags = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/tags`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insPasswordReset = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { email };
      const url = `${config.apiUrl}/auth/password`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const updPassword = (token, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { token, password };
      const url = `${config.apiUrl}/auth/password`;
      const res = await http.put(url, body);
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const updPost = (postID, title, content, editorState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = { title, content, editorState };
      const url = `${config.apiUrl}/posts/${postID}`;
      const { data } = await http.put(url, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getInvitations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/users/invite`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insImage = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const httpConfig = {
        headers: {
          "x-upload-path": "posts",
        },
      };
      const data = new FormData();
      data.append("upload", file);
      const insImage = `${config.apiUrl}/images`;
      const res = await http.post(insImage, data, httpConfig);
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getImages = (name, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const qsData = { page };
      if (name) {
        qsData.name = name;
      }
      const qString = qs.encode(qsData);
      const url = `${config.apiUrl}/images?${qString}`;
      const { data, headers } = await http.get(url);
      resolve({
        data,
        nextPage: headers["next-page"] === "null" ? null : headers["next-page"],
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getLocations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/events/locations`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const insEvent = (
  name,
  scheduledTime,
  locationID,
  cost,
  content,
  editorState
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        name,
        scheduledTime,
        locationID,
        cost,
        content,
        editorState,
      };
      const url = `${config.apiUrl}/events`;
      const { data } = await http.post(url, body);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getEventDetails = (eventID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/events/${eventID}`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getEventRSVPTypes = (eventID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/events/${eventID}/rsvp/types`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getEventMenuItems = (eventID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${config.apiUrl}/events/${eventID}/menu`;
      const { data } = await http.get(url);
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
};

export const upsertRSVP = (eventID, rsvpType, guests) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        type: rsvpType,
        guests,
      };
      const url = `${config.apiUrl}/events/${eventID}/rsvp`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const upsertMenuOrder = (eventID, menuItems) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        menuItems,
      };
      const url = `${config.apiUrl}/events/${eventID}/orders`;
      await http.post(url, body);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};
