import React, { Component } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createLinkPlugin from "@draft-js-plugins/anchor";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
} from "@draft-js-plugins/buttons";
import editorStyles from "./editorStyles.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import Button from "@mui/material/Button";
import createImagePlugin from "@draft-js-plugins/image";
import ImageAdd from "../image-add";

const linkPlugin = createLinkPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const imagePlugin = createImagePlugin();
const plugins = [inlineToolbarPlugin, toolbarPlugin, linkPlugin, imagePlugin];
const text = "Replace with Post content...";

export default class SimpleLinkPluginEditor extends Component {
  state = {
    editorState: this.props.previousState
      ? EditorState.createWithContent(convertFromRaw(this.props.previousState))
      : createEditorStateWithText(text),
  };

  onChange = (editorState) => this.setState({ editorState });

  focus = () => this.editor.focus();

  render() {
    return (
      <>
        <div className={editorStyles.editor} onClick={this.focus}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
          <InlineToolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <div>
                  <HeadlineOneButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <HeadlineThreeButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                </div>
              )
            }
          </InlineToolbar>
          <br />
          <Toolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps) => (
                <div>
                  <UnorderedListButton {...externalProps} />
                  <br />
                  <OrderedListButton {...externalProps} />
                </div>
              )
            }
          </Toolbar>
        </div>
        <br />
        <ImageAdd
          editorState={this.state.editorState}
          onChange={this.onChange}
          modifier={imagePlugin.addImage}
        />
        <br />
        <Button
          onClick={() => {
            this.props.getEditorContent(this.state.editorState);
          }}
        >
          Stage Content
        </Button>
      </>
    );
  }
}
