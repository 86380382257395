import React from "react";
import { authCheck, adminCheck } from "../services/auth";

const ProtectedRoute = (props) => {
  const { component: Component } = props;
  if (!authCheck() || !adminCheck()) {
    return (window.location = "/logout");
  }
  return <Component {...props} />;
};

export default ProtectedRoute;
