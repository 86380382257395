import React, { useState, useEffect } from "react";
import { getInvitations } from "../services/controller";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dateFormat from "dateformat";
import ProfilePicture from "./components/profile-picture";

const Invitations = (props) => {
  const { handleSetAlert } = props;
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState([]);

  const fetchInvitations = async () => {
    try {
      setLoading(true);
      const newInvitations = await getInvitations();
      setInvitations(newInvitations);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  return (
    <div className="invitations container">
      <h1>Invitations</h1>
      {!loading && (
        <>
          <center>
            <TableContainer component={Paper} sx={{ width: "95%" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Invited By</TableCell>
                    <TableCell>Invited Date</TableCell>
                    <TableCell>Converted</TableCell>
                    <TableCell>Username</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invitations.map((i) => (
                    <TableRow
                      key={i.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {i.email}
                      </TableCell>
                      <TableCell>
                        <ProfilePicture user={i.invitedBy} size={25} />
                        {i.invitedBy.username}
                      </TableCell>
                      <TableCell>
                        {dateFormat(i.sysInserted, "mmmm dS, yyyy h:MM TT")}
                      </TableCell>
                      <TableCell>{i.converted ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        {i.converted && <ProfilePicture user={i} size={25} />}
                        {i.converted ? i.username : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </center>
        </>
      )}
      <br />
      <br />
      <br />
    </div>
  );
};

export default Invitations;
