import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import * as _ from "lodash";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfilePicture from "./components/profile-picture";
import {
  getPostDetails,
  insLike,
  delLike,
  insComment,
  delComment,
} from "../services/controller";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Post = (props) => {
  const { handleSetAlert, user } = props;
  const { postID } = useParams();
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState();
  const [comment, setComment] = useState();

  const fetchPostDetails = async () => {
    try {
      setLoading(true);
      const postDetails = await getPostDetails(postID);
      setPost(postDetails);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleSetAlert("error", "Error fetching post");
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchPostDetails();
    }
  }, [user]);

  const handleLikeClick = async (e) => {
    try {
      const updPost = { ...post };
      if (updPost.likes.userLiked) {
        await delLike(postID);
        updPost.likes.userLiked = false;
        updPost.likes.count = parseInt(updPost.likes.count) - 1;
      } else {
        await insLike(postID);
        updPost.likes.userLiked = true;
        updPost.likes.count = parseInt(updPost.likes.count) + 1;
      }
      setPost(updPost);
    } catch (err) {
      handleSetAlert("error", "Unexpected Error.");
    }
  };

  const handleCommentClick = async () => {
    try {
      const updPost = { ...post };
      await insComment(postID, comment);
      const newPost = {
        id: 0,
        user,
        content: comment,
        sysInserted: new Date(),
      };
      updPost.comments.push(newPost);
      setPost(updPost);
      setComment("");
    } catch (err) {
      handleSetAlert("error", "Error submitting comment.");
    }
  };

  const handleDeleteCommentClick = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const commentID = parseInt(idArray[idArray.length - 1]);
      // TODO: Fix this in the API and return the actual comment ID
      if (commentID === 0) {
        return handleSetAlert(
          "warning",
          "Please refresh the page first to delete the comment."
        );
      }
      await delComment(commentID);
      const updPost = { ...post };
      const commentIndex = _.findIndex(updPost.comments, { id: commentID });
      updPost.comments.splice(commentIndex, 1);
      setPost(updPost);
    } catch (err) {
      handleSetAlert("error", "Error deleting comment.");
    }
  };

  return (
    <div className="post-page-container">
      <center>
        {loading && (
          <Box sx={{ width: "25%" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && post && (
          <Box>
            <div className="post-content-container">
              <div className="likes">
                <Button
                  id={`btn-post-like-${postID}`}
                  // variant="outlined"
                  className={post.likes.userLiked ? "liked" : "unliked"}
                  startIcon={
                    post.likes.userLiked ? (
                      <ThumbUpIcon />
                    ) : (
                      <ThumbUpOutlinedIcon />
                    )
                  }
                  onClick={handleLikeClick}
                >
                  {post.likes.count}
                </Button>
              </div>
              <div className="user container">
                <ProfilePicture user={post.user} size={40} />
                <p>{post.user.username}</p>
              </div>
              <p className="post-date">
                {dateFormat(post.sysInserted, "mmmm dS, yyyy h:MM TT")}
              </p>
              <br />
              <h1>{post.title}</h1>
              <div className="tags container">
                {post.tags.map((t) => {
                  return <Chip key={t.id} label={t.value} variant="outlined" />;
                })}
              </div>
              <center>
                <Box className="content">
                  <div
                    style={{ textAlign: "left", margin: 25 }}
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></div>
                </Box>
              </center>
            </div>
            {/* <div className="content-container">
            </div> */}
            <div className="comments-container">
              <Box>
                <Stack spacing={2}>
                  {post.comments.map((c) => {
                    return (
                      <center>
                        <div className="comment container" key={c.id}>
                          <Box>
                            <div className="user container">
                              <ProfilePicture user={c.user} size={40} />
                              <p className="username">{c.user.username}</p>
                              <p className="post-date">
                                {dateFormat(
                                  c.sysInserted,
                                  "mmmm dS, yyyy h:MM TT"
                                )}
                              </p>
                            </div>
                            <p className="comment-content" align="left">
                              {c.content}
                            </p>
                            {user.id === c.user.id && (
                              <IconButton
                                id={`btn-comment-del-${c.id}`}
                                aria-label="delete"
                                onClick={handleDeleteCommentClick}
                                className="delete-btn"
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </div>
                      </center>
                    );
                  })}
                </Stack>
                <TextareaAutosize
                  className="input-box"
                  aria-label="minimum height"
                  minRows={4}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Comment..."
                />
                <br />
                <Button className="submit-btn" onClick={handleCommentClick}>
                  Submit
                </Button>
              </Box>
            </div>
          </Box>
        )}
      </center>
    </div>
  );
};

export default Post;
