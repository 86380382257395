import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authCheck } from "../services/auth";
import http from "../services/http";
import config from "../config";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Register = (props) => {
  const { handleSetUser, handleSetAlert } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  if (authCheck()) {
    return (window.location = "/");
  }

  const handleRegisterClick = async () => {
    try {
      const reqBody = {
        email,
        username,
        password,
        token,
      };
      const reqUrl = `${config.apiUrl}/users`;
      const res = await http.post(reqUrl, reqBody);
      const authToken = res.headers[config.authHeader];
      handleSetUser(authToken);
      return (window.location = "/");
    } catch (err) {
      handleSetAlert("error", err.response.data.message);
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      handleRegisterClick();
    }
  };

  return (
    <div className="login container">
      <h1>Register</h1>
      <center>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
        >
          <Stack spacing={2} direction="column">
            <TextField
              id="token-input"
              label="Invitation Token"
              variant="outlined"
              value={token}
              disabled
            />
            <TextField
              id="email-input"
              label="Email"
              variant="outlined"
              value={email}
              disabled
            />
            <TextField
              id="username-input"
              label="Username"
              variant="outlined"
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              id="password-input"
              label="Password"
              type="password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => handleKeypress(e)}
            />
            <Button variant="login" onClick={handleRegisterClick}>
              Register
            </Button>
          </Stack>
        </Box>
      </center>
    </div>
  );
};

export default Register;
