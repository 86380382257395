import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SearchCriteria = ({
  tagList,
  handleAddTag,
  handleAddSortBy,
  handleApplyFilters,
}) => {
  const sortByList = [
    {
      code: "date-old",
      value: "Date (Oldest to Newest)",
    },
    {
      code: "date-new",
      value: "Date (Newest to Oldest)",
    },
  ];

  return (
    <div className="filter-container">
      <Autocomplete
        disablePortal
        className="filter-input-container"
        id="tag-picker"
        options={tagList}
        getOptionLabel={(tag) => tag.value}
        style={{ width: 300 }}
        onChange={(event, newValue) => {
          handleAddTag(newValue);
        }}
        renderInput={(params) => (
          <TextField
            className="filter-input"
            {...params}
            label="Tags"
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        disablePortal
        className="filter-input-container"
        id="sort-by-picker"
        options={sortByList}
        getOptionLabel={(sortBy) => sortBy.value}
        style={{ width: 300 }}
        onChange={(event, newValue) => {
          handleAddSortBy(newValue);
        }}
        renderInput={(params) => (
          <TextField
            className="filter-input"
            {...params}
            label="Sort By"
            variant="outlined"
          />
        )}
      />
      <Button
        onClick={() => {
          handleApplyFilters();
        }}
        className="apply-btn"
      >
        Apply
      </Button>
    </div>
  );
};

export default SearchCriteria;
