import React from "react";
import { authCheck, verifiedCheck } from "../services/auth";

const ProtectedRoute = (props) => {
  const { component: Component } = props;
  if (!authCheck() || !verifiedCheck()) {
    return (window.location = "/logout");
  }
  return <Component {...props} />;
};

export default ProtectedRoute;
