import { getPosts, getImages, getTags, getLocations } from "./controller";

export const wait = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const fetchPosts = async (
  setLoading,
  page,
  setNextPage,
  tag,
  sortBy,
  pageSize,
  currentData,
  setData,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getPosts(tag, sortBy, pageSize, page);
    setData([...currentData, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchImages = async (
  setLoading,
  page,
  setNextPage,
  images,
  setImages,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const { data, nextPage } = await getImages(undefined, page);
    setImages([...images, ...data]);
    setNextPage(nextPage);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchTags = async (setLoading, setTagList, handleSetAlert) => {
  try {
    setLoading(true);
    const newTagList = await getTags();
    setTagList(newTagList);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};

export const fetchLocations = async (
  setLoading,
  setLocations,
  handleSetAlert
) => {
  try {
    setLoading(true);
    const locations = await getLocations();
    setLocations(locations);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    if (err.response) {
      handleSetAlert("warning", err.response.data.message);
    } else {
      handleSetAlert("error", err.message);
    }
  }
};
