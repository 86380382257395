import React, { Component } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Button from "@mui/material/Button";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class ControlledEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props.previousState
        ? EditorState.createWithContent(
            convertFromRaw(this.props.previousState)
          )
        : EditorState.createEmpty(),
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;

    return (
      <div className="content-editor container">
        <Editor
          editorState={editorState}
          wrapperClassName="content-editor-wrapper"
          editorClassName="content-editor-text"
          wrapperStyle={{
            borderStyle: "ridge",
            padding: "10px",
          }}
          editorStyle={{
            borderStyle: "ridge",
            padding: "10px",
            backgroundColor: "white",
          }}
          onEditorStateChange={this.onEditorStateChange}
        />
        <br />
        <Button
          onClick={() => {
            this.props.getEditorContent(this.state.editorState);
          }}
        >
          Stage Content
        </Button>
      </div>
    );
  }
}

export default ControlledEditor;
