import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as _ from "lodash";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InlineToolbarEditor from "./components/text-editor/inline-toolbar";
import Box from "@mui/material/Box";
import {
  updPost,
  getTags,
  insTag,
  insPostTag,
  delPostTag,
  getPostDetailsForEdit,
  getImages,
  insImage,
} from "../services/controller";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentEditor from "./components/content-editor";

const EditPost = (props) => {
  const { handleSetAlert, user } = props;
  const { postID } = useParams();
  const [loading, setLoading] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [title, setTitle] = useState("");
  const [stateJSON, setStateJSON] = useState();
  const [stateHTML, setStateHTML] = useState();
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [images, setImages] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageSearch, setImageSearch] = useState();
  const [imageNextPage, setImageNextPage] = useState(1);

  const handleInsertImage = async () => {
    try {
      if (imageFile) {
        const newImage = await insImage(imageFile);
        setImages([newImage, ...images]);
        setImageFile();
        handleSetAlert("success", "Image Uploaded!");
      }
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const fetchImages = async () => {
    try {
      const { data, nextPage } = await getImages(imageSearch, imageNextPage);
      setImages([...images, ...data]);
      setImageNextPage(nextPage);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const newTagList = await getTags();
      setTagList(newTagList);
      const { data, nextPage } = await getImages(imageSearch, imageNextPage);
      setImages([...images, ...data]);
      setImageNextPage(nextPage);
      const postDetails = await getPostDetailsForEdit(postID);
      setTitle(postDetails.title);
      setTags(postDetails.tags);
      setStateJSON(postDetails.editorState);
      setStateHTML(postDetails.content);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchData();
    }
  }, [user]);

  const fetchTags = async () => {
    try {
      const newTagList = await getTags();
      setTagList(newTagList);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const getEditorContent = (childEditorState) => {
    if (childEditorState) {
      const currentContent = childEditorState.getCurrentContent();
      const rawContent = convertToRaw(currentContent);
      setStateJSON(JSON.stringify(rawContent));
      setStateHTML(draftToHtml(rawContent));
    }
  };

  const handleAddTag = async (newValue) => {
    try {
      if (newValue) {
        const tagExists = _.find(tags, { id: newValue.id });
        if (!tagExists) {
          await insPostTag(postID, newValue.id);
          const updTags = [...tags, newValue];
          setTags(updTags);
          handleSetAlert("success", "Tag Added.");
        }
      }
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleRemoveTag = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const tagID = parseInt(idArray[idArray.length - 1]);
      await delPostTag(postID, tagID);
      const updTags = [...tags];
      const tagIndex = _.findIndex(tags, { id: tagID });
      updTags.splice(tagIndex, 1);
      setTags(updTags);
      handleSetAlert("success", "Tag Removed.");
    } catch (err) {
      console.log(err);
      handleSetAlert("error", "Error removing tag.");
    }
  };

  const handleCreateTag = async () => {
    try {
      if (newTag !== "") {
        await insTag(newTag);
        fetchTags();
        setNewTag("");
        handleSetAlert("success", "Tag Created");
      }
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleUpdatePost = async () => {
    try {
      await updPost(postID, title, stateHTML, stateJSON);
      handleSetAlert("success", "Post Updated!");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="create-post container">
      <h1>Edit Post</h1>
      {loading && (
        <center>
          <Box sx={{ width: "25%" }}>
            <CircularProgress />
          </Box>
        </center>
      )}
      {!loading && (
        <>
          <div>
            <center>
              <TextField
                id="title-input"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ width: "50%" }}
              />
              <br />
              <br />
              <Divider sx={{ width: "75%" }} />
              <br />
              <h2>Content</h2>
              <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
                <div style={{ margin: 25 }}>
                  <ContentEditor
                    getEditorContent={getEditorContent}
                    previousState={stateJSON}
                  />
                </div>
              </Box>
            </center>
          </div>
          <br />
          <br />
          <br />
          <h2>Preview</h2>
          <center>
            <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
              <div
                style={{ textAlign: "left", margin: 25 }}
                dangerouslySetInnerHTML={{ __html: stateHTML }}
              ></div>
            </Box>
          </center>
          <br />
          <Button
            onClick={() => {
              handleUpdatePost();
            }}
          >
            Update Post
          </Button>
          <br />
          <br />
          <div className="images container">
            <h2>Images</h2>
            <center>
              <Box sx={{ width: "75%", border: 1, borderRadius: "16px" }}>
                <br />
                <input
                  type="file"
                  name="Image Upload"
                  onChange={(e) => setImageFile(e.target.files[0])}
                />
                <Button variant="login" onClick={handleInsertImage}>
                  Upload Image
                </Button>
                <br />
                <br />
                {/* <TextField
            id="image-name-input"
            label="Image Name"
            variant="outlined"
            onChange={(e) => setImageSearch(e.target.value)}
          />
          <br />
          <Button
            onClick={() => {
              setImageNextPage(1);
              setImages([]);
              fetchImages();
            }}
            className="search-btn"
          >
            Search
          </Button> */}
                <br />
                <br />
                <TableContainer component={Paper} sx={{ width: "90%" }}>
                  <Table sx={{ width: "90%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Image Name</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {images.map((i) => (
                        <TableRow
                          key={i.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {i.fileName}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              id={`btn-image-copy-${i.id}`}
                              aria-label="Copy Link"
                              onClick={() => {
                                navigator.clipboard.writeText(i.imageLink);
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                {imageNextPage && (
                  <center>
                    <Button
                      onClick={() => {
                        fetchImages();
                      }}
                      className="load-btn"
                    >
                      Load More...
                    </Button>
                  </center>
                )}
                <br />
              </Box>
            </center>
          </div>
          <br />
          <br />
          <center>
            <Divider sx={{ width: "75%" }} />
          </center>
          <br />
          <div className="tags container">
            <h2>Tags</h2>
            <center>
              <TableContainer component={Paper} sx={{ width: "50%" }}>
                <Table sx={{ width: "90%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Tag</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tags.map((t) => (
                      <TableRow
                        key={t.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {t.value}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            id={`btn-tag-del-${t.id}`}
                            aria-label="delete"
                            onClick={handleRemoveTag}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </center>
            <br />
            <br />
            <center>
              <Autocomplete
                id="tag-picker"
                options={tagList}
                getOptionLabel={(tag) => tag.value}
                style={{ width: 300 }}
                onChange={(event, newValue) => {
                  handleAddTag(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" variant="outlined" />
                )}
              />
            </center>
            <h2>Create Tag</h2>
            <center>
              <TextField
                id="create-tag-input"
                label="New Tag"
                variant="outlined"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
              />
              <br />
              <br />
              <Button
                onClick={() => {
                  handleCreateTag();
                }}
              >
                Create Tag
              </Button>
            </center>
          </div>
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default EditPost;
