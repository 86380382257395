import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { adminCheck } from "../services/auth";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import CreateIcon from "@mui/icons-material/Create";
import BallotIcon from "@mui/icons-material/Ballot";
import EventIcon from "@mui/icons-material/Event";

export default function SwipeableTemporaryDrawer(props) {
  const { user, menuOpen, handleMenuClose, handleMenuOpen } = props;

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleMenuClose}
      onKeyDown={handleMenuClose}
      className="drawer-container"
    >
      <h1>Menu</h1>
      <List>
        {!user.email && (
          <ListItem button component={Link} to={"/login"}>
            <ListItemIcon className="menu-icon-container">
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        )}
        {user.email && (
          <>
            <ListItem button component={Link} to={"/"}>
              <ListItemIcon className="menu-icon-container">
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="The Den" />
            </ListItem>
            <ListItem button component={Link} to={"/settings"}>
              <ListItemIcon className="menu-icon-container">
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button component={Link} to={"/logout"}>
              <ListItemIcon className="menu-icon-container">
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
            {/* <ListItem button component={Link} to={"/donate"}>
              <ListItemIcon>
              <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Donate" />
            </ListItem> */}
            {adminCheck() && (
              <>
                <br />
                <br />
                <Divider />
                <h2>Admin</h2>
                <ListItem button component={Link} to={"/create-post"}>
                  <ListItemIcon className="menu-icon-container">
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText primary="New Post" />
                </ListItem>
                <ListItem button component={Link} to={"/create-event"}>
                  <ListItemIcon className="menu-icon-container">
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary="New Meeting" />
                </ListItem>
                <ListItem button component={Link} to={"/invite"}>
                  <ListItemIcon className="menu-icon-container">
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Invite User" />
                </ListItem>
                <ListItem button component={Link} to={"/view-invites"}>
                  <ListItemIcon className="menu-icon-container">
                    <BallotIcon />
                  </ListItemIcon>
                  <ListItemText primary="View Invitations" />
                </ListItem>
              </>
            )}
          </>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="left"
        open={menuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
