import config from "../config";
import _ from "lodash";
import jwtDecode from "jwt-decode";

export const authCheck = () => {
  const auth = sessionStorage.getItem(config.authHeader);
  return auth ? true : false;
};

export const verifiedCheck = () => {
  if (!sessionStorage.getItem(config.authHeader)) {
    return false;
  }
  const { groups: userGroups } = jwtDecode(
    sessionStorage.getItem(config.authHeader)
  );
  if (userGroups === []) {
    return false;
  }
  const userGroupIDs = userGroups.map((g) => g.id);
  const verifiedGroupIDs = [1, 2, 3];
  const inGroup = _.intersection(verifiedGroupIDs, userGroupIDs);
  if (inGroup.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const adminCheck = () => {
  if (!sessionStorage.getItem(config.authHeader)) {
    return false;
  }
  const { groups: userGroups } = jwtDecode(
    sessionStorage.getItem(config.authHeader)
  );
  if (userGroups === []) {
    return false;
  }
  const userGroupIDs = userGroups.map((g) => g.id);
  const adminGroupIDs = [1];
  const inGroup = _.intersection(adminGroupIDs, userGroupIDs);
  if (inGroup.length > 0) {
    return true;
  } else {
    return false;
  }
};
