import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import jwtDecode from "jwt-decode";
import config from "./config";
import http from "./services/http";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Guards
import ProtectedRoute from "./guards/protected-route";
import AdminRoute from "./guards/admin-route";

// Content
import NavBar from "./content/nav-bar";
import Menu from "./content/menu";
import Home from "./content/home";
import NotFound from "./content/not-found";
import Login from "./content/login";
import Logout from "./content/logout";
// import Admin from "./content/admin";
import Invite from "./content/invite";
import Register from "./content/register";
import Settings from "./content/settings";
import Post from "./content/post";
import CreatePost from "./content/create-post";
import ForgotPassword from "./content/forgot-password";
import ResetPassword from "./content/reset-password";
import EditPost from "./content/edit-post";
import Invitations from "./content/invitations";
import CreateEvent from "./content/create-event";
import Event from "./content/event";

function App(props) {
  const [user, setUser] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alert, setAlert] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem(config.authHeader)) {
      const storedUser = jwtDecode(sessionStorage.getItem(config.authHeader));
      setUser(storedUser);
      http.setCommonHeader(
        config.authHeader,
        sessionStorage.getItem(config.authHeader)
      );
    }
  }, []);

  const handleSetUser = (authToken) => {
    sessionStorage.setItem(config.authHeader, authToken);
    http.setCommonHeader(config.authHeader, authToken);
    const tokenUser = jwtDecode(authToken);
    setUser(tokenUser);
  };

  const handleClearUser = () => {
    sessionStorage.removeItem(config.authHeader);
    http.setCommonHeader(config.authHeader, "");
    setUser({});
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleSetAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlert(message);
    setShowAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div className="App">
      <NavBar
        user={user}
        menuOpen={menuOpen}
        handleMenuClose={handleMenuClose}
        handleMenuOpen={handleMenuOpen}
      />
      <Menu
        user={user}
        menuOpen={menuOpen}
        handleMenuClose={handleMenuClose}
        handleMenuOpen={handleMenuOpen}
      />
      {/* Added breaks because of navbar styling.
      Components would start behind the navbar
      Probably a better way to do this */}
      <br />
      <br />
      <br />
      <Routes>
        <Route
          path="/invite"
          element={
            <AdminRoute
              component={Invite}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/view-invites"
          element={
            <AdminRoute
              component={Invitations}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/create-post"
          element={
            <AdminRoute
              component={CreatePost}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/edit-post/:postID"
          element={
            <AdminRoute
              component={EditPost}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/create-event"
          element={
            <AdminRoute
              component={CreateEvent}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/register"
          element={
            <Register
              {...props}
              handleSetUser={handleSetUser}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ForgotPassword {...props} handleSetAlert={handleSetAlert} />
          }
        />
        <Route
          path="/reset-password"
          element={
            <ResetPassword
              {...props}
              handleSetUser={handleSetUser}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Login
              {...props}
              handleSetUser={handleSetUser}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/logout"
          element={<Logout handleClearUser={handleClearUser} />}
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              component={Settings}
              user={user}
              handleSetUser={handleSetUser}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/event/:eventID"
          element={
            <ProtectedRoute
              component={Event}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/post/:postID"
          element={
            <ProtectedRoute
              component={Post}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute
              component={Home}
              user={user}
              handleSetAlert={handleSetAlert}
            />
          }
          exact
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showAlert}
        onClose={handleAlertClose}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
