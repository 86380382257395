import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { insPost, insTag, insPostTag, insImage } from "../services/controller";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentEditor from "./components/content-editor";
import { fetchImages, fetchTags } from "../services/helpers";

const CreatePost = (props) => {
  const { handleSetAlert, user } = props;
  const [loading, setLoading] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [title, setTitle] = useState();
  const [stateJSON, setStateJSON] = useState();
  const [stateHTML, setStateHTML] = useState();
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [images, setImages] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageNextPage, setImageNextPage] = useState(1);

  useEffect(() => {
    if (user.username) {
      fetchImages(
        setLoading,
        imageNextPage,
        setImageNextPage,
        images,
        setImages,
        handleSetAlert
      );
      fetchTags(setLoading, setTagList, handleSetAlert);
    }
  }, [user]);

  const getEditorContent = (childEditorState) => {
    if (childEditorState) {
      const currentContent = childEditorState.getCurrentContent();
      const rawContent = convertToRaw(currentContent);
      setStateJSON(JSON.stringify(rawContent));
      setStateHTML(draftToHtml(rawContent));
    }
  };

  const handleInsertImage = async () => {
    try {
      if (imageFile) {
        const newImage = await insImage(imageFile);
        setImages([newImage, ...images]);
        setImageFile();
        handleSetAlert("success", "Image Uploaded!");
      }
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleAddTag = async (newValue) => {
    try {
      if (newValue) {
        const tagExists = _.find(tags, { id: newValue.id });
        if (!tagExists) {
          const updTags = [...tags, newValue];
          setTags(updTags);
        }
      }
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleRemoveTag = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const tagID = parseInt(idArray[idArray.length - 1]);
      const updTags = [...tags];
      const tagIndex = _.findIndex(tags, { id: tagID });
      updTags.splice(tagIndex, 1);
      setTags(updTags);
    } catch (err) {
      console.log(err);
      handleSetAlert("error", "Error removing tag.");
    }
  };

  const handleCreateTag = async () => {
    try {
      if (newTag !== "") {
        await insTag(newTag);
        fetchTags(setLoading, setTagList, handleSetAlert);
        setNewTag("");
        handleSetAlert("success", "Tag Created");
      }
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleCreatePost = async () => {
    try {
      const { id: postID } = await insPost(title, stateHTML, stateJSON);
      for (let t = 0; t < tags.length; t++) {
        const { id: tagID } = tags[t];
        await insPostTag(postID, tagID);
      }
      handleSetAlert("success", "Post Created!");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="create-post container">
      <h1>New Post</h1>
      <div>
        <center>
          <TextField
            id="title-input"
            label="Title"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
            sx={{ width: "50%" }}
          />
          <br />
          <br />
          <Divider sx={{ width: "75%" }} />
          <br />
          <h2>Content</h2>
          <br />
          <br />
          <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
            <div style={{ margin: 25 }}>
              <ContentEditor getEditorContent={getEditorContent} />
            </div>
          </Box>
        </center>
      </div>
      <br />
      <br />
      <br />
      <h2>Preview</h2>
      <center>
        <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
          <div
            style={{ textAlign: "left", margin: 25 }}
            dangerouslySetInnerHTML={{ __html: stateHTML }}
          ></div>
        </Box>
      </center>
      <br />
      <br />
      <center>
        <Divider sx={{ width: "75%" }} />
      </center>
      <br />
      <div className="images container">
        <h2>Images</h2>
        <center>
          <Box sx={{ width: "75%", border: 1, borderRadius: "16px" }}>
            <br />
            <input
              type="file"
              name="Image Upload"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
            <Button variant="login" onClick={handleInsertImage}>
              Upload Image
            </Button>
            <br />
            <br />
            <br />
            <br />
            <TableContainer component={Paper} sx={{ width: "90%" }}>
              <Table sx={{ width: "90%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Image Name</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {images.map((i) => (
                    <TableRow
                      key={i.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {i.fileName}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          id={`btn-image-copy-${i.id}`}
                          aria-label="Copy Link"
                          onClick={() => {
                            navigator.clipboard.writeText(i.imageLink);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            {imageNextPage && (
              <center>
                <Button
                  onClick={() => {
                    fetchImages(
                      setLoading,
                      imageNextPage,
                      setImageNextPage,
                      images,
                      setImages,
                      handleSetAlert
                    );
                  }}
                  className="load-btn"
                >
                  Load More...
                </Button>
              </center>
            )}
            <br />
          </Box>
        </center>
      </div>
      <br />
      <br />
      <center>
        <Divider sx={{ width: "75%" }} />
      </center>
      <br />
      <div className="tags container">
        <h2>Tags</h2>
        <center>
          <TableContainer component={Paper} sx={{ width: "50%" }}>
            <Table sx={{ width: "90%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tag</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((t) => (
                  <TableRow
                    key={t.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {t.value}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        id={`btn-tag-del-${t.id}`}
                        aria-label="delete"
                        onClick={handleRemoveTag}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </center>
        <br />
        <br />
        <center>
          <Autocomplete
            id="tag-picker"
            options={tagList}
            getOptionLabel={(tag) => tag.value}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              handleAddTag(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tags" variant="outlined" />
            )}
          />
        </center>
        <h2>Create Tag</h2>
        <center>
          <TextField
            id="create-tag-input"
            label="New Tag"
            variant="outlined"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
          <br />
          <br />
          <Button
            onClick={() => {
              handleCreateTag();
            }}
          >
            Create Tag
          </Button>
        </center>
      </div>
      <br />
      <br />
      <center>
        <Divider sx={{ width: "75%" }} />
      </center>
      <br />
      <Button
        onClick={() => {
          handleCreatePost();
        }}
      >
        Create Post
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreatePost;
