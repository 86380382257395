import React, { useState } from "react";
import http from "../services/http";
import config from "../config";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Invite = (props) => {
  const { handleSetAlert } = props;
  const [email, setEmail] = useState("");

  const handleInviteClick = async () => {
    try {
      const reqBody = {
        email,
      };
      const reqUrl = `${config.apiUrl}/users/invite`;
      await http.post(reqUrl, reqBody);
      handleSetAlert("success", `Invitation sent to ${email}`);
    } catch (err) {
      handleSetAlert("error", err.response.data.message);
    }
  };

  return (
    <div className="invite container">
      <h1>Invite New User</h1>
      <center>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
        >
          <Stack spacing={2} direction="column">
            <TextField
              id="email-input"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="login" onClick={handleInviteClick}>
              Invite
            </Button>
          </Stack>
        </Box>
      </center>
    </div>
  );
};

export default Invite;
