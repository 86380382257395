import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { insImage, insEvent } from "../services/controller";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentEditor from "./components/content-editor";
import { fetchImages, fetchLocations } from "../services/helpers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const CreateEvent = (props) => {
  const { handleSetAlert, user } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [locations, setLocations] = useState([]);
  const [locationID, setLocationID] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [cost, setCost] = useState();
  const [stateJSON, setStateJSON] = useState();
  const [stateHTML, setStateHTML] = useState();
  const [images, setImages] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageNextPage, setImageNextPage] = useState(1);

  useEffect(() => {
    if (user.username) {
      fetchImages(
        setLoading,
        imageNextPage,
        setImageNextPage,
        images,
        setImages,
        handleSetAlert
      );
      fetchLocations(setLoading, setLocations, handleSetAlert);
    }
  }, [user]);

  const getEditorContent = (childEditorState) => {
    if (childEditorState) {
      const currentContent = childEditorState.getCurrentContent();
      const rawContent = convertToRaw(currentContent);
      setStateJSON(JSON.stringify(rawContent));
      setStateHTML(draftToHtml(rawContent));
    }
  };

  const handleInsertImage = async () => {
    try {
      if (imageFile) {
        const newImage = await insImage(imageFile);
        setImages([newImage, ...images]);
        setImageFile();
        handleSetAlert("success", "Image Uploaded!");
      }
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  const handleCreateEvent = async () => {
    try {
      await insEvent(
        name,
        scheduledTime,
        locationID,
        cost,
        stateHTML,
        stateJSON
      );
      handleSetAlert("success", "Meeting Created!");
    } catch (err) {
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="create-event container">
      <h1>New Meeting</h1>
      <div>
        <center>
          <TextField
            id="name-input"
            label="Meeting Name"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            sx={{ width: "50%" }}
          />
          <br />
          <br />
          <Autocomplete
            id="location-picker"
            options={locations}
            getOptionLabel={(l) => l.name}
            style={{ width: "50%" }}
            onChange={(event, newValue) => {
              setLocationID(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Location" variant="outlined" />
            )}
          />
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["DateTimePicker"]}
              sx={{ width: "50%" }}
            >
              <DateTimePicker
                label="Scheduled Date & Time"
                onChange={(newValue) => {
                  const newDateTime = new Date(newValue).getTime();
                  setScheduledTime(newDateTime);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <br />
          <br />
          <TextField
            id="cost-input"
            label="Cost $"
            variant="outlined"
            onChange={(e) => setCost(e.target.value)}
            sx={{ width: "50%" }}
          />
          <br />
          <br />
          <Divider sx={{ width: "75%" }} />
          <br />
          <h2>Content</h2>
          <br />
          <br />
          <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
            <div style={{ margin: 25 }}>
              <ContentEditor getEditorContent={getEditorContent} />
            </div>
          </Box>
        </center>
      </div>
      <br />
      <br />
      <h2>Preview</h2>
      <center>
        <Box sx={{ width: "90%", border: 1, borderRadius: "16px" }}>
          <div
            style={{ textAlign: "left", margin: 25 }}
            dangerouslySetInnerHTML={{ __html: stateHTML }}
          ></div>
        </Box>
      </center>
      <br />
      <br />
      <center>
        <Divider sx={{ width: "75%" }} />
      </center>
      <br />
      <div className="images container">
        <h2>Images</h2>
        <center>
          <Box sx={{ width: "75%", border: 1, borderRadius: "16px" }}>
            <br />
            <input
              type="file"
              name="Image Upload"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
            <Button variant="login" onClick={handleInsertImage}>
              Upload Image
            </Button>
            <br />
            <br />
            <br />
            <br />
            <TableContainer component={Paper} sx={{ width: "90%" }}>
              <Table sx={{ width: "90%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Image Name</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {images.map((i) => (
                    <TableRow
                      key={i.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {i.fileName}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          id={`btn-image-copy-${i.id}`}
                          aria-label="Copy Link"
                          onClick={() => {
                            navigator.clipboard.writeText(i.imageLink);
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            {imageNextPage && (
              <center>
                <Button
                  onClick={() => {
                    fetchImages(
                      setLoading,
                      imageNextPage,
                      setImageNextPage,
                      images,
                      setImages,
                      handleSetAlert
                    );
                  }}
                  className="load-btn"
                >
                  Load More...
                </Button>
              </center>
            )}
            <br />
          </Box>
        </center>
      </div>
      <br />
      <br />
      <center>
        <Divider sx={{ width: "75%" }} />
      </center>
      <br />
      <Button
        onClick={() => {
          handleCreateEvent();
        }}
      >
        Create Meeting
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateEvent;
