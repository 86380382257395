import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { insLike, delLike, getTags } from "../services/controller";
import { fetchPosts } from "../services/helpers";
import PostSummary from "./components/post-summary";
import SearchCriteria from "./components/search-criteria";

const Home = (props) => {
  const { handleSetAlert, user } = props;
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [tag, setTag] = useState();
  const [sortBy, setSortBy] = useState();
  const [pageSize, setPageSize] = useState();
  const [applyFilters, setApplyFilters] = useState(false);
  const [tagList, setTagList] = useState([]);

  const fetchTags = async () => {
    try {
      const newTagList = await getTags();
      setTagList(newTagList);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchTags();
      fetchPosts(
        setLoading,
        nextPage,
        setNextPage,
        applyFilters ? tag : undefined,
        applyFilters ? sortBy : undefined,
        pageSize,
        posts,
        setPosts,
        handleSetAlert
      );
    }
  }, [user]);

  const handleLoadMore = () => {
    if (nextPage) {
      fetchPosts(
        setLoadingMore,
        nextPage,
        setNextPage,
        applyFilters ? tag : undefined,
        applyFilters ? sortBy : undefined,
        pageSize,
        posts,
        setPosts,
        handleSetAlert
      );
    }
  };

  const handleLikeClick = async (e) => {
    try {
      const idArray = e.currentTarget.id.split("-");
      const postID = parseInt(idArray[idArray.length - 1]);
      const updPosts = [...posts];
      const postIndex = _.findIndex(updPosts, { id: postID });
      if (updPosts[postIndex].likes.userLiked) {
        await delLike(postID);
        updPosts[postIndex].likes.userLiked = false;
        updPosts[postIndex].likes.count =
          parseInt(updPosts[postIndex].likes.count) - 1;
      } else {
        await insLike(postID);
        updPosts[postIndex].likes.userLiked = true;
        updPosts[postIndex].likes.count =
          parseInt(updPosts[postIndex].likes.count) + 1;
      }
      setPosts(updPosts);
    } catch (err) {
      handleSetAlert("error", "Unexpected Error.");
    }
  };

  const handleAddTag = async (newValue) => {
    if (newValue) {
      setTag(newValue.id);
    } else {
      setTag(undefined);
    }
  };

  const handleAddSortBy = async (newValue) => {
    if (newValue) {
      setSortBy(newValue.code);
    } else {
      setSortBy(undefined);
    }
  };

  const handleApplyFilters = () => {
    setApplyFilters(true);
    fetchPosts(
      setLoading,
      1,
      setNextPage,
      applyFilters ? tag : undefined,
      applyFilters ? sortBy : undefined,
      pageSize,
      [],
      setPosts,
      handleSetAlert
    );
  };

  return (
    <div className="home container">
      <center>
        {loading && (
          <Box sx={{ width: "25%" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box>
            <SearchCriteria
              tagList={tagList}
              handleAddTag={handleAddTag}
              handleAddSortBy={handleAddSortBy}
              handleApplyFilters={handleApplyFilters}
            />
            <Stack spacing={2}>
              {posts.map((p) => {
                return (
                  <PostSummary post={p} handleLikeClick={handleLikeClick} />
                );
              })}
            </Stack>
          </Box>
        )}
      </center>
      {nextPage && (
        <center>
          <Button
            onClick={() => {
              handleLoadMore();
            }}
            className="load-btn"
          >
            Load More...
          </Button>
        </center>
      )}
      {loadingMore && (
        <center>
          <Box sx={{ width: "25%" }}>
            <CircularProgress />
          </Box>
        </center>
      )}
    </div>
  );
};

export default Home;
