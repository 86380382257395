const config = {
  apiUrl:
    process.env["REACT_APP_DC_API_ENV"] === "development"
      ? "http://localhost:4001"
      : "https://api.diggersden.org",
  authHeader: "x-auth-token",
  mediaUrl: "http://c3store.nyc3.digitaloceanspaces.com",
};

export default config;
