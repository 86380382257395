import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { updProfilePicture } from "../services/controller";
import config from "../config";

const Invite = (props) => {
  const { handleSetAlert, handleSetUser } = props;
  const [imageFile, setImageFile] = useState();

  const handleUpdateImage = async () => {
    try {
      if (imageFile) {
        const res = await updProfilePicture(imageFile);
        const authToken = res.headers[config.authHeader];
        handleSetUser(authToken);
        setImageFile();
        handleSetAlert("success", "Profile Picture Updated!");
      }
    } catch (err) {
      if (err.response.data.message) {
        handleSetAlert("warning", err.response.data.message);
      } else {
        handleSetAlert("error", err.message);
      }
    }
  };

  return (
    <div className="invite container">
      <h1>Profile</h1>
      <center>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          // noValidate
          // autoComplete="off"
        >
          <Stack spacing={2} direction="column">
            <input
              type="file"
              name="Profile Image Upload"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
            <Button variant="login" onClick={handleUpdateImage}>
              Update Profile Picture
            </Button>
          </Stack>
        </Box>
      </center>
    </div>
  );
};

export default Invite;
