import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ProfilePicture from "./components/profile-picture";
import { Link } from "react-router-dom";

export default function ButtonAppBar(props) {
  const { handleMenuOpen, user } = props;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="nav-bar" position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography to={"/"} component={Link} className="nav-title" variant="h6" sx={{ flexGrow: 1 }}>
            Digger's Den
          </Typography>
          <ProfilePicture user={user} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
